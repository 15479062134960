import { format } from 'date-fns';
import $ from 'jquery';

function caseInsensitiveMatch(a, b) {
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
}

function formatRFC7231DateOnly(datetime) {
  return format(datetime, 'E, dd MMM yyyy');
}

function getRequest(url, params = {}) {
  return new Promise((resolve, reject) => {
    $.get(url, params).done(resolve).fail(reject);
  });
}

function postRequest(url, params) {
  return new Promise((resolve, reject) => {
    $.post(url, params).done(resolve).fail(reject);
  });
}

function getParam(name) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export {
  caseInsensitiveMatch,
  formatRFC7231DateOnly,
  getParam,
  getRequest,
  postRequest,
};
